import { db } from '@/firebase/config'
import wrapWithCache from '@/composables/wrapWithCache'

const getFilteredOpps = async (store) => {
  try {
    await db.collection("Opportunities").get().then(async docs => {
      const now = new Date(Date.now())
      let result = []
      let promises = []
      docs.forEach(doc => {
        const run = Promise.resolve((async () => {
          const docRef = await db.collection("Opportunities").doc(doc.id).collection("registered")
          const docData = doc.data()
          let applicantsCount = 0
          let juniorAcceptedCount = 0
          await docRef.get().then(documents => {
            documents.forEach(document => {
              applicantsCount++
              if (document.data().status == "accepted") {
                juniorAcceptedCount++;
              }
            })
          })
          if ((docData.deadline.toDate() > now || true) && juniorAcceptedCount < docData.limit) {
            result.push({ ...docData, id: doc.id })
          }
        })())
        promises.push(run)
      });
      await Promise.all(promises)
      store.value = result
    })
  } catch (error) {
    alert(error)
  }
};

export default wrapWithCache(getFilteredOpps, "filteredOpps")
