<template>
  <li class="referral" v-bind:id="data.id">
    <h3 class="opp-category">
      <p>{{ data.category }}</p>
    </h3>
    <div>
      <h2>{{ modifyLength(data.title, 20) }}</h2>
      <h3>{{ modifyLength(data.company, 15) }}</h3>
      <h3 class="opp-alumni">from {{ profile.firstName + " " + profile.lastName }}</h3>
    </div>
  </li>
</template>

<script>
import { ref } from "vue";
import getProfile from "@/composables/Profile/getProfile";

export default {
  props: ["data"],
  async setup(props) {
    const profile = ref(null);

    await getProfile(profile, props.data.alumniId);

    const modifyLength = (title, limit) => {
      let result
      if (title.length <= limit) {
        result = title
      }
      else{
        result = title.substring(0, limit) + "..."
      }
      return result
    }

    return {
      profile,
      modifyLength
    }
  },
};
</script>

<style>
.referral {
  margin: 3vh 2vw;
  flex: 0 0 28%;
  border-radius: 3vh;
  height: 30vh;
  min-width: 200px;
  cursor: pointer;
  animation: fadein 1s;
  list-style: none;
}

.opp-category {
  height: 9vh;
  font-weight: normal;
  padding-left: 2vw;
  padding-right: 2vw;
  background-color: var(--purple-l);
  border-radius: 3vh 3vh 0 0;
  display: flex;
  justify-content: center;
}

.opp-category p{
  width: 100%;
  margin: auto;
  color: white;
}

.referral div {
  width: 100%;
  box-shadow: 0px 2vh 3vh rgba(0, 0, 0, 0.25);
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 2vh 2vw;
  height: 20vh;
  border-radius: 0 0 3vh 3vh;
  background-color: white;
}

.referral div h3{
  font-weight: 400;
}

.opp-alumni {
  position: absolute;
  bottom: 10%;
}
</style>
